import * as React from "react";

import { Map } from "@shared-ui/lodging-property-details";
import { IdentifierType, ProductHeadline } from "@shared-ui/retail-product-headline";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { PropertySummaryHeaderProps } from "./typings";
import { useDialog } from "@shared-ui/dialog-context";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { useRefContext } from "@shared-ui/ref-context";

const WrappedPropertyMap = PropertyBexApiWrapper(Map);

export const PropertySummaryHeader: React.FC<PropertySummaryHeaderProps> = ({ context, templateComponent }) => {
  const [isMapDialogOpen, mapDialogActions, MapDialogComponent] = useDialog("property-address-map");
  const triggerRef = React.useRef<HTMLElement>(null);
  const { getTargets } = useRefContext();
  const addressLinkTarget = getTargets(["propertyAddressMapLink"])["propertyAddressMapLink"];

  if (!templateComponent) {
    return null;
  }
  const { enableInlineRating = false, showAddress } = templateComponent.config;

  const inputs = {
    productIdentifier: {
      id: `${context.searchContext.location.id}`,
      type: IdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: {
          primary: {
            dateRange: null,
            rooms: [{ adults: 2 }],
            destination: {
              regionId: String(context.searchContext.location.parent.id),
            },
          },
          secondary: {},
        },
      },
    },
  };

  return (
    <>
      <ProductHeadline inputs={inputs} inlineRating={enableInlineRating} showAddress={showAddress} />
      <UitkSheetTransition isVisible={isMapDialogOpen}>
        <MapDialogComponent>
          <WrappedPropertyMap
            context={context}
            onClose={() => {
              mapDialogActions.closeDialog();
              addressLinkTarget.current?.focus();
            }}
            triggerRef={triggerRef}
          />
        </MapDialogComponent>
      </UitkSheetTransition>
    </>
  );
};
export default PropertySummaryHeader;
